

/* Adjustment */
.container-scroller .btn {
  margin: 0px !important;
}
.bg-white-theme {
  background-color: #f1f2f4;
}
.nav-tabs .nav-link {
  background: #f5f5f5;
  color: black;
}
.nav-tabs .nav-link.active {
  color: #035fb3 !important;
  background-color: #fff !important;
  border-bottom: 2px solid #035fb3 !important;
}
.row {
  padding: 10px;
}
.border-neglect {
  border-width: 0px;
}
.tab-content {
  border-width: 0px;
  padding-top: 10px;
  padding-left: 0px;
  padding-right: 0px;
}
.select-drop {
  border: 1px solid rgb(147, 147, 147);
  border-radius: 4px;
  padding: 4px;
}
.custom-btn {
  background: #fff;
  color: #007cb9;
  border: 2px solid #007cb9;
  border-radius: 3px;
  padding: 5px 10px;
  text-transform: uppercase;
}
.side-sheet {
  box-shadow: 2px 4px 8px #585858;
  padding: 0px;
}
.custom-grey-btn {
  background: #f2f2f2;
  color: #212121;
  border: 1px solid #c2c2c2;
  border-radius: 3px;
  padding: 5px 10px;
  font-size: small;
}
.picker {
  color: #b2b2b3;
  color: #e0dede;
}
.no-margin {
  margin: 0px;
}

.bank-details-table {
  background: #eeeeee;
}

table {
  border-collapse: collapse;
  margin: 0px;
}
.no-glow {
  outline: none;
  box-shadow: none !important;
  border: 1px solid #ccc !important;
}

.my-drop {
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.card-hover:hover {
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}
.login-hover-link {
  color: #fff;
}
.login-hover-link:hover {
  text-decoration: none;
  color: yellow;
  cursor: pointer;
}

.txt-prime {
  color: #010147;
}

/* Zebra striping */
/* tr:nth-of-type(odd) {
  background: #eee;
} */

th {
  background: #3498db;
  color: white;
  font-weight: bold;
}

td,
th {
  padding: 10px;
  border: 1px solid #ccc;
  text-align: left;
  font-size: 18px;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

/* ADMIN TABLE STYLE */
.admin-table-style td,
th {
  padding: 10px;
  border: 2px solid #000;
  text-align: center;
  font-size: 18px;
  font-weight: bolder;
}

.admin-table-style th {
  background: #3676d8;
  color: white;
  font-weight: bolder;
}
.admin-fix-width {
  width: 100%;
}
.admin-table-style tr:nth-of-type(odd) {
  background: #fff;
  font-weight: bolder;
}
.admin-table-style tr:nth-of-type(even) {
  background: #fff;
  font-weight: bolder;
}

/* NORMAL TABLE STYLE */
.normal-table-style td,
th {
  padding: 10px;
  border: 2px solid #000;
  text-align: left;
  font-size: 18px;
  font-weight: bolder;
}

.normal-table-style th {
  background: #3676d8;
  color: white;
  font-weight: bolder;
}
.normal-fix-width {
  width: 100%;
}
.normal-table-style tr:nth-of-type(odd) {
  background: #fff;
  font-weight: bolder;
}
.normal-table-style tr:nth-of-type(even) {
  background: #fff;
  font-weight: bolder;
}

/* DMT Table Style */

.dmt-table-style table {
  width: 100%;
  table-layout: fixed;
}
.dmt-tbl-header {
  background-color: #000;
}
.dmt-tbl-content {
  height: 300px;
  overflow-x: auto;
  margin-top: 0px;
  border: 1px solid rgba(255, 255, 255, 0.3);
}
.dmt-table-style th {
  padding: 8px 8px;
  text-align: left;
  font-weight: 800;
  font-size: 1rem;
  color: #fff;
  background: #4b56d2;
  text-transform: uppercase;
  border: none;
}
.dmt-table-style td {
  padding: 8px 8px;
  text-align: left;
  vertical-align: middle;
  font-weight: 800;
  font-size: 0.8rem;
  color: #000;
  border: solid 1px rgba(255, 255, 255, 0.1);
}
.dmt-table-style tr:nth-of-type(odd) {
  background: #ecf2ff;
  font-weight: bolder;
}
.dmt-table-style tr:nth-of-type(even) {
  background: #fff;
  font-weight: bolder;
}
.dmt-table-selected-left {
  border-left: 4px solid #4b56d2 !important;
}

/* DMT Table Style */

.hover {
  cursor: pointer;
}
.hover-2 {
  cursor: pointer;
}
.hover-2:hover {
  color: red !important;
}

/* Dashboard Table Design */
/* Dashboard */

.dashboard-table-style td,
th {
  padding: 10px;
  border: none;
  text-align: center;
  font-size: 18px;
}

.dashboard-table-style th {
  background: #ffffff;
  color: #000000;
  font-weight: bolder;
  border-bottom: 1px solid #cccccc;
  border-top: 1px solid #cccccc;
}
.dashboard-fix-width {
  width: 100% !important;
}
.dashboard-table-style tr:nth-of-type(odd) {
  background: #ffffff;
  border-bottom: 1px solid #cccccc;
}
.dashboard-table-style tr:nth-of-type(even) {
  background: #ffffff;
  border-bottom: 1px solid #cccccc;
}

.dashboard-table-style td {
  color: #000000;
}

/* Income Table Design */
/* Income Table */

.income-calculator-table-style td,
th {
  padding: 10px;
  border: none;
  font-size: 0.8rem;
}

.income-calculator-table-style th {
  background: #ffffff;
  color: #000000;
  font-weight: bolder;
  border-bottom: 1px solid #cccccc;
  border-top: 1px solid #cccccc;
}
.income-calculator-fix-width {
  width: 100% !important;
}
.income-calculator-table-style tr:nth-of-type(odd) {
  background: #ffffff;
  /* border-bottom: 1px solid #cccccc; */
}
.income-calculator-table-style tr:nth-of-type(even) {
  background: #ffffff;
  /* border-bottom: 1px solid #cccccc; */
}

.income-calculator-table-style td {
  color: #000000;
}

.nav-pills.nav-pills-custom .nav-link.active {
  background: #50c3c4;
}

.nav-pills.nav-pills-custom .nav-link.active {
  background: #50c3c4;
}
.nav-pills.nav-pills-custom .nav-link {
  border-radius: 1em !important;
}

.nav-pills.nav-pills-custom-2 .nav-link {
  border-radius: 0px !important;
}

@media print {
  .hide-on-print {
    display: none !important;
  }
}

/* Naveen  */

/* naveen */

@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");

.d-class1 {
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 2px 6px 2px !important;
  background: #fff !important;
  border-radius: 20px !important;
  font-family: "Poppins", sans-serif !important;
  position: relative !important;
}

.btn-ttt {
  border: none !important;
  border-radius: 20px !important;
  color: #ffffff !important;
  background: linear-gradient(to right, #4fc3c6 0%, #7fc99f 100%) !important;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 2px 6px 2px !important;
  cursor: pointer !important;
  font-family: Poppins !important;
}

.th-ffff {
  background: #ffffff !important;
  color: #000000 !important;
  padding: 0.5rem 1rem !important;
  border: none !important;
  font-family: Poppins !important;
}

.td-rrrr {
  border: none;
}

.btn-ttt:focus {
  border: none !important;
  scale: 1.1 !important;
  transition: 0.5s !important;
}

.btn-tttt {
  border: none !important;
  color: #ffffff !important;
  background: linear-gradient(to right, #65a7db 0%, #426bb4 100%);
  font-size: 0.8rem !important;
  cursor: pointer !important;
  position: relative !important;
  font-family: Poppins !important;
}

.btn-fffff {
  color: #000000 !important;
  background: none !important;
  font-size: 0.8rem !important;
  cursor: pointer !important;
  position: relative !important;
  font-family: Poppins !important;
}

.btn-fffff:hover {
  border: 1px solid black !important;
  background-color: none !important;
}
@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");

.d-class1 {
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 2px 6px 2px !important;
  background: #fff !important;
  border-radius: 20px !important;
  font-family: "Poppins", sans-serif !important;
  position: relative !important;
}

.btn-ttt {
  border: none !important;
  border-radius: 20px !important;
  color: #ffffff !important;
  background: linear-gradient(to right, #4fc3c6 0%, #7fc99f 100%) !important;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 2px 6px 2px !important;
  cursor: pointer !important;
  font-family: Poppins !important;
}

.th-ffff {
  background: #ffffff !important;
  color: #000000 !important;
  padding: 0.5rem 1rem !important;
  border: none !important;
  font-family: Poppins !important;
}

.td-rrrr {
  border: none;
}

.btn-ttt:focus {
  border: none !important;
  scale: 1.1 !important;
  transition: 0.5s !important;
}

.btn-tttt {
  border: none !important;
  color: #ffffff !important;
  background: linear-gradient(to right, #65a7db 0%, #426bb4 100%);
  font-size: 0.8rem !important;
  cursor: pointer !important;
  position: relative !important;
  font-family: Poppins !important;
}

.btn-fffff {
  color: #000000 !important;
  background: none !important;
  font-size: 0.8rem !important;
  cursor: pointer !important;
  position: relative !important;
  font-family: Poppins !important;
}

.btn-fffff:hover {
  border: 1px solid black !important;
  background-color: none !important;
}

.flightbanner h1,
.flightbanner h3,
.flightbanner h6 {
  margin: 0;
  /* text-shadow: 2px 2px 6px #000; */
  text-align: left;
  font-family: Poppins;
}

.flightbanner {
  background: url("./assets/images/flight.png");
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  justify-content: left;
  align-items: flex-start;
}

@media (max-width: 997px) {
  
  .flightbanner {
    background: url("./assets/images/flight.png");
    height: 100vh;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center, center;
  }

  .flightbanner h1,
  .flightbanner h3,
  .flightbanner h6 {
    /* text-shadow: 2px 2px 6px #000; */
    text-align: left;
    font-size: 1.5em;
    font-family: Poppins;
    align-items: flex-start;
  }
}

.d-class23 {
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
  background: #fff;
  border-radius: 20px;

  font-family: "Poppins", sans-serif;
  position: relative;
}
.btn-primary-fff {
  background-color: #ffffff;
  border-color: #000000;

  color: black;

  font-family: "Poppins", sans-serif;
  position: relative;
}
.btn-primary-ffff {
  color: rgb(255, 255, 255);
  background: linear-gradient(90deg, #65a7db 0%, #3a6beb 100%);
  font-family: "Poppins", sans-serif;
  position: relative;
}

.btn-primary-ffffff {
  color: rgb(255, 255, 255);
  background: linear-gradient(90deg, #ec5665 0%, #c12740 100%);
  font-family: "Poppins", sans-serif;
  position: relative;
}

.form-label {
  margin-bottom: 0.5rem;
  font-family: "Poppins", sans-serif;
  color: grey;
}

.btn-price {
  border: none !important;
  color: #ffffff !important;
  border-radius: 10px !important;

  font-size: 1.2 !important;
  cursor: pointer !important;
  position: relative !important;
  font-family: Poppins !important;
  width: 100% !important;
}

._loading_overlay_overlay {
  z-index: 100000 !important;
}

.react-tel-input .form-control{
  margin: 0px !important;
  width: 100% !important;
}


/* image preview */

.image-overlays {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999; 
}

.image-overlays img {
  max-width: 100%;
  max-height: 80vh;
}


.nav-pills.nav-pills-custom-5 .nav-link.active {
  background:#FFFFFF;
  color:#4267B2;
  box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.1);

 
}




/* collection gateway pms */
.PMS-collection-table th{
  background-color: #fff;
  color: #000;
  font-weight: bolder;
  font-size: .9rem;
  border: none;
  }
  
  .PMS-collection-table td,
  th {
    padding: 10px;
    border: none;
    text-align: center;
    font-size: 15px;
  }
  .PMS-collection-table {
    width: 100% !important;
  }
  .PMS-collection-table tr:nth-of-type(odd) {
    background: #ffffff;
    border-bottom: 1px solid #cccccc;
  }
  .PMS-collection-table tr:nth-of-type(even) {
    background: #ffffff;
    border-bottom: 1px solid #cccccc;
  }
  
  .PMS-collection-table td {
    color: #000000;
  }
  .Collection-gateway-icons{
    /* transition: border-color 0.3s; */
    transform: scale(1,1);
  }

  .PMS-community-head{
    color: grey;
  }
 
  .addbank{
    background: #ffffff !important;
  
  }
  .addbank:hover{
    background: grey !important;
    color: #fff !important;
  }
  .PMS_text:hover{
    font-weight: bold;
    color: #000;
  }
  .community:hover{
    transform: scale(1.1);
  }
  /* #popover-positioned-bottom{
    transform: translate3d(1015px, 203px, 0px) !important;
  } */
  /* Switch Styles */
.switch {
  position: relative;
  display: inline-block;
  width:4em;
  height: 2em;
  background-color: #ccc;
  border-radius: 10px;
  cursor: pointer;
}

.switch-handle {
  position: absolute;
  top: .4em;
  left: 2px;
  width: 3em;
  height: 2.5em;
  background-color: #fff;
  border-radius: 1em;
  transition: left 0.3s ease;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: .6em;
}

.switch-on {
  background-color: #86d6ad;
}

.switch-on .switch-handle {
  left: 22px;
}

.switch-off-handle {
  left: 2px;
}

.switch-off {
  background-color: #ccc;
}

.chart-container {
  width:15em;
  height: 7.5em;
  border-width:2em;
}
.chart-container canvas {
  border-width: 0.5px !important;
}

/* image preview of tower image  */
.image-overlays {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999; 
}

.image-overlays img {
  max-width: 100%;
  max-height: 80vh;
}
/* CSS */
.green-link {
  color: green; 
  text-decoration: none; 
}

.green-link.active {
  border-bottom: 2px solid green;
}
.red-checkbox {
  background-color: red;
}


.active-tab {
 border: 1px solid #0074d9 !important;
}
.bold-text {
  font-weight: bold !important;
  color: #0074d9 !important;
}



/* drag and drop */
.file-drop-area {
  border: 2px dashed #0087f7;
  background-color: #f4f9ff;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 20px;
}

.dragging {
  border-color: #0056b3;
  background-color: #f0f8ff;
}

.file-input {
  margin-top: 10px;
  display: none;
}
.file-drop-area p {
  margin-top: 10px;
}
.file-input-label:hover {
  background-color: #f0f0f0; 
  cursor: pointer;
}






#cont
{
  width: 700px;
  height: 100px;
  border: 1px solid #0f0;
  overflow: hidden;
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
}
.item{
  display: inline-block;
  margin:20px;
  padding: 10px;
  background: #aaa;
  min-width: 50px;
  box-shadow: 2px 2px 4px #000;
}

/* web salary */
.insta:hover{
  height: 3rem;
  /* content: url('/src/assets/images/instagram.png');  */
}
.facebook:hover{
  height: 3rem;
  /* content: url('/src/assets/images/faceb.png');  */
}
.linkdin:hover{
  height: 3rem;
  /* content: url('/src/assets/images/linkedin.png');  */
}
.twitter:hover{
  height: 3rem;
  /* content: url('/src/assets/images/twitnew.png');  */
}
.sliderweb:hover{
  transform: scale(1.2); 
  cursor: pointer;
}

.sliderweb {
  height: 3rem;
  margin-right: 3rem !important; /* Adjust the spacing between icons */
  margin-top: 2rem;
}


@media screen and (max-width: 576px) {
  .solutiondiv{
    display: flex;
    justify-content: center;
  }
  .solutionpic {
    max-height: 25vh;
    justify-content: center;
    display: flex; /* Adjust the height for even smaller screens */
  }
  .sliderpics{
    width: 24rem;
  }
  .mainpic{
    height: 27vh;
  }
  .heading{
    font-size: 1rem;
  }
  .womenhead{
    font-size: 1rem;
  }
  .women{
    height: 10.5rem;
  }
  .womenLink{
    height: 0rem !important;
    font-size: 1rem !important;
  }
  .Womenonly{
    height: 22vh;
  }
  .service_laptop {
    display: none !important;
  }
  .service_phone{
    display: block !important;
  }
}

@media screen and (min-width: 577px) {
  .heading {
    font-size: 2rem;
  }
  .women{
    height: 27rem;
  }
  .womenhead{
/* font-size: 2rem; */

  }
  .womenLink{
    height: 2.5rem !important;
  }
  .main{
    height:"70vh"
  }
  .mainpic{
    height: 27rem;
  }
  .fot{
    /* width: 75%; */
    display: flex;
    justify-content: center !important;
  }
  .sliderpics{
    width: 40%;
  }
  .Womenonly{
    margin-top: -11rem;
    height: 50vh;
  }
  .service_laptop {
    display: block !important;
  }
  .service_phone{
    display: none !important;
  }
}

@media screen and (min-width: 1444px) {
  .billpunchlogo{
margin-left: 2.8rem;
  }
.largee{
  height: 42vh;
}
  .women{
    height: 35rem;
  }
  .mainpic{
    height: auto;
  }
  .reviewcard{
    height: 30vh;
    width: 40vh;
  }
  .large-screen-col {
    flex: 0 0 25% !important; 
   
  }
  .expertcard{
    height: 17vh !important;
  }
  .Womenonly{
    margin-top: -11rem;
    height: 40vh;
  }
  .sliderpics{
    width: 50%;
  }
  .service_laptop {
    display: block !important;
  }
  .service_phone{
    display: none !important;
  }
}
@media (max-width: 768px) {
  .Womenonly {
    height: 22rem;
  }
  .service_laptop {
    display: none !important;
  }
  .service_phone{
    display: block !important;
  }
  .sliderpics{
    width: 40%;
  }
}

.dot-click-slider {
  position: relative;
}

.dot-indicators {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: center;
}

.dot-indicators span {
  width: 10px;
  height: 10px;
  background-color: #000; 
  border-radius: 50%;
  margin: 0 5px;
  cursor: pointer;
}

.dot-indicators span.active {
  background-color: #555; 
}
.dot-click-slider-container {
  position: relative;
}

.dot-indicators {
  position: absolute;
  bottom: 4px; 
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: center;
}

.dot-indicators span {
  width: 10px;
  height: 10px;
  background-color: #000;
  border-radius: 50%;
  margin: 0 5px;
  cursor: pointer;
}

.dot-indicators span.active {
  background-color: #555;
}
.carousel-control-prev{
  display: none !important;
}
.carousel-control-next{
  display: none !important;
}
.carousel-indicators{
 display: none !important;
}
.salaryslip-modal .modal-content{
  width: 45rem !important;
}
.Vendor-modal .modal-content{
  width: 40rem !important;
}
.expensepayrolmodal .modal-content{
  width: 35rem !important;
}
.policypdf .modal-content{
  width: 60rem !important;
}
.Adminflowmodal .modal-content{
  width: 45rem !important;
}
.chechattandance-modal .modal-content{
  width: 35rem !important;
} 
.EPFmodal .modal-content{
  width: 45rem !important;
}
.fc .fc-widget-header table tr th span{
  color: #fff !important;
  font-size: .8rem;
}
.color-box {
  width: 2rem;
  height: 2rem;
  border: 1px solid #000;
  margin: 5px;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 1rem;
}
/* Override FullCalendar's default styles */
.empcalender .fc-header-toolbar {
  margin-top: 0 !important;
}
.disablepop{
  z-index: 100 !important;
}

.remove-container {
  position: relative;
}

.remove-text {
  position: absolute;
  top: 50%;
  left: calc(100% + 5px); /* Adjust the distance from the icon */
  transform: translate(-50%, -50%);
  opacity: 0;
  transition: opacity 0.3s ease;
}

.remove-container:hover .remove-text {
  opacity: 1;
}

.hover-cardplan {
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}

.hover-cardplan:hover {
  transform: scale(1.05); /* Scales up the card */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Adds shadow to lift the card */
}
.section-title .divider {
  position: relative;
  border-bottom: 1px solid #f0f0f0;
  margin-bottom: 30px;
  margin-top: 30px;
}

.section-title .divider:before {
  position: absolute;
  content: '';
  width: 30px;
  height: 30px;
  border: 1px solid #f0f0f0;
  left: 50%;
  margin-left: -15px;
  top: 50%;
  background: #fff;
  margin-top: -15px;
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.section-title .divider:after {
  position: absolute;
  content: '';
  width: 20px;
  height: 20px;
  border: 1px solid #2ca5b9;
  left: 50%;
  margin-left: -10px;
  top: 50%;
  background: #2ca5b9;
  margin-top: -10px;
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}


.shape{ 
  border-style: solid; border-width: 0 70px 40px 0; float:right; height: 0px; width: 0px;
  -ms-transform:rotate(360deg); /* IE 9 */
  -o-transform: rotate(360deg);  /* Opera 10.5 */
  -webkit-transform:rotate(360deg); /* Safari and Chrome */
  transform:rotate(360deg);
}
.offer{
  background:#fff; border:1px solid #ddd; box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2); margin: 15px 0; overflow:hidden;
}
.offer-radius{
  border-radius:7px;
}
.offer-danger { border-color: #d9534f; }
.offer-danger .shape{
  border-color: transparent #d9534f transparent transparent;
  border-color: rgba(255,255,255,0) #d9534f rgba(255,255,255,0) rgba(255,255,255,0);
}
.offer-success {    border-color: #5cb85c; }
.offer-success .shape{
  border-color: transparent #5cb85c transparent transparent;
  border-color: rgba(255,255,255,0) #5cb85c rgba(255,255,255,0) rgba(255,255,255,0);
}
.offer-default {    border-color: #999999; }
.offer-default .shape{
  border-color: transparent #999999 transparent transparent;
  border-color: rgba(255,255,255,0) #999999 rgba(255,255,255,0) rgba(255,255,255,0);
}
.offer-primary {    border-color: #428bca; }
.offer-primary .shape{
  border-color: transparent #428bca transparent transparent;
  border-color: rgba(255,255,255,0) #428bca rgba(255,255,255,0) rgba(255,255,255,0);
}
.offer-info {   border-color: #5bc0de; }
.offer-info .shape{
  border-color: transparent #5bc0de transparent transparent;
  border-color: rgba(255,255,255,0) #5bc0de rgba(255,255,255,0) rgba(255,255,255,0);
}
.offer-warning {    border-color: #f0ad4e; }
.offer-warning .shape{
  border-color: transparent #f0ad4e transparent transparent;
  border-color: rgba(255,255,255,0) #f0ad4e rgba(255,255,255,0) rgba(255,255,255,0);
}

.shape-text{
  color:#fff; font-size:12px; font-weight:bold; position:relative; right:-40px; top:2px; white-space: nowrap;
  -ms-transform:rotate(30deg); 
  -o-transform: rotate(360deg);  
  -webkit-transform:rotate(30deg); 
  transform:rotate(30deg);
}   
.offer-content{
  padding:0 20px 10px;
}
.custom-scrollbaractivity {
  height: 80vh;
  overflow: auto;
  scrollbar-width: thin; 
  scrollbar-color: transparent transparent !important; 
}

.custom-scrollbaractivity::-webkit-scrollbar {
  width: 0; 
}
.blurred {
  filter: blur(4px);
}
/* WeeklyCalendar.css */

.weekly-calendar {
  max-width: 600px;
  margin: 0 auto;
  padding: 1rem;
  border: 1px solid #ccc;
  border-radius: 8px;
  background: cornsilk;
}

.weekly-calendar-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}

.weekly-calendar-nav-button {
  background-color: transparent;
  border: none;
  color: #333;
  font-size: 1.5rem;
  cursor: pointer;
}

.weekly-calendar-title {
  font-size: 1.2rem;
}

.weekly-calendar-days {
  display: flex;
  gap: 10px;
}

.weekly-calendar-day {
  text-align: center;
}

.weekly-calendar-day-label {
  font-size: 0.9rem;
}

.weekly-calendar-day-number {
  font-size: 1.2rem;
  padding: 10px;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.weekly-calendar-current-day {
  background-color: #41B06E;
  color: #fff;
  border-radius: 1rem;
}
.initial-container {
  display: flex;
  align-items: center;
}

.initial {
  position: relative;
  background: #e1afd1;
  border-radius: 50%;
  width: 2rem; 
  height: 2rem; 
  margin-left: -1rem; 
  line-height: 2rem; 
  text-align: center;
  transition: background-color 0.3s ease;
}

.employee-name {
  position: absolute;
  top: -2.5rem; 
  left: 50%;
  transform: translateX(-50%);
  opacity: 0;
  pointer-events: none;
  white-space: nowrap;
  background: #333;
  color: #fff;
  padding: 0.5rem;
  border-radius: 0.5rem;
  font-size: 0.8rem;
  transition: opacity 0.3s ease;
}

.initial:hover .employee-name {
  opacity: 1;
}

.initial + .initial {
  margin-left: -0.5rem; 
}
/* Define a unique class name */
.firm-dashboard-zigzag {
  position: relative;
  display: inline-block;
}

/* Define the zigzag line with multiple colors */
.firm-dashboard-zigzag::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: -5px; /* Adjust this value to control the distance from the text */
  width: 100%;
  height: 5px;
  background-image: linear-gradient(45deg, #ff0000 33.33%, #FFBB70 33.33%, #FFBB70 66.66%, #FFEC9E 66.66%);
  background-size: 8px 8px; 
}
.fancy {
  --b: 6px;  
  --w: 80px;  
  --g: 15px;  
  --c: #0B486B;
 
  width: fit-content;
  padding: 0 1em;
  line-height: 1.6em;
  border: 1px solid;
  color: #fff;
  background: 
    conic-gradient(from   45deg at left ,var(--c) 25%,#0000 0) 0,
    conic-gradient(from -135deg at right,var(--c) 25%,#0000 0) 100%;
  background-size: 51% 100%;
  background-origin: border-box;
  background-repeat: no-repeat;
  border-image: 
     linear-gradient(
       #0000      calc(50% - var(--b)/2),
       var(--c) 0 calc(50% + var(--b)/2),
       #0000    0) 
    1/0 var(--w)/calc(var(--w) + var(--g));
  margin-inline: auto;
}



.corners--round {
  mask-image: 
    radial-gradient(
      circle at 1.5rem 1.5rem,
      transparent 1.5rem, 
      gold 0
    );
  mask-position: -1.5rem -1.5rem;
  mask-repeat: repeat;
}


.corners--repeated-circles {
  mask-image: 
    radial-gradient(
      circle at 1.5rem 1.5rem,
      transparent 0, transparent 1rem, 
      gold 0, gold 1.25rem,
      transparent 0, transparent 1.5rem, 
      gold 0
    );
  mask-position: -1.5rem -1.5rem;
  mask-repeat: repeat;
}





.unique-form .title {
  font-family: 'Pacifico', cursive;
  color: #212529;
  font-size: 2.5rem;
}

.unique-form .form-control {
  background-color: #f2f6f8;
  border-radius: 2rem;
  border: none;
  box-shadow: 0px 7px 5px rgba(0, 0, 0, 0.11);
}

.unique-form .form-control.thick {
  height: 3.3rem;
  padding: .5rem 3.5rem;
}

.unique-form .form-control:focus {
  background-color: #f2f6f8;
  border: none;
  box-shadow: 0px 7px 5px rgba(0, 0, 0, 0.11);
}

.unique-form .message .form-control {
  padding: .5rem 1.8rem;
}

.unique-form ::placeholder {
  font-family: 'Quicksand', sans-serif;
  font-weight: 600;
  font-size: 1.1rem;
  color: #838788;
  position: relative;
  left: 0;
}

.unique-form input,
.unique-form textarea {
  font-family: 'Quicksand', sans-serif;
  color: #212529;
  font-size: 1.1rem;
}

.unique-form .icon {
  color: #57565c;
  height: 1.3rem;
  position: absolute;
  left: 1.5rem;
  top: 1.1rem;
}

.unique-form .btn.btn-primary {
  font-family: 'Quicksand', sans-serif;
  font-weight: bold;
  height: 2.5rem;
  line-height: 2.5rem;
  padding: 0 3rem;
  border: 0;
  border-radius: 3rem;
  background-image: linear-gradient(131deg, #ffd340, #ff923c, #ff923c, #ff923c);
  background-size: 300% 100%;
  transition: all 0.3s ease-in-out;
}

.unique-form .btn.btn-primary:hover:enabled {
  box-shadow: 0 0.5em 0.5em -0.4em #ff923cba;
  background-size: 100% 100%;
  transform: translateY(-0.15em);
}

.billpunchcontact{
  font-family: 'Pacifico', cursive;
  color: #212529;
  font-size: 2.5rem; 
}
.form-group.position-relative label::before {
  content: "\f0ac";
  font-family: 'Font Awesome 5 Free';
  font-weight: 900;
  font-size: 1.2rem;
  color: #57565c;
  position: absolute;
  left: 1.5rem;
  top: 1.1rem;
}
/* Add this CSS to your stylesheet */

.adminemp-tooltip-icon {
  cursor: pointer;
}

.adminemp-tooltip-text {
  visibility: hidden;
  width: 120px;
  background-color: #fff;
  color: #000;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  bottom: 125%; /* Position the tooltip above the icon */
  left: 50%;
  margin-left: -60px; /* Center the tooltip */
  opacity: 0;
  transition: opacity 0.3s;
}

.adminemp-tooltip-text::after {
  content: "";
  position: absolute;
  top: 100%; /* At the bottom of the tooltip */
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: black transparent transparent transparent; /* Arrow pointing down */
}

.adminemp-tooltip-container:hover .adminemp-tooltip-text {
  visibility: visible;
  opacity: 1;
}

@import url('https://fonts.googleapis.com/css?family=Poppins:400,500,600,700&display=swap');

.navsalary1 {
  box-sizing: border-box;
  display: flex;
  height: 4rem;
  width: 100%;
  background: #fff;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  position: sticky;
  top: 0;
  z-index: 100;
}

.navsalary1 .logo {
  color: #fff;
  font-size: 35px;
  font-weight: 600;
}

.navsalary1 ul {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
}

.navsalary1 ul li {
  margin: 0 5px;
}

.navsalary1 ul li a {
  color: #000;
  text-decoration: none;
  font-size: 18px;
  font-weight: 500;
  padding: 8px 15px;
  border-radius: 5px;
  letter-spacing: 1px;
  transition: all 0.3s ease;
}

.navsalary1 .menu-btn i {
  color: #000;
  font-size: 22px;
  cursor: pointer;
  display: none;
}

.navsalary1 input[type="checkbox"] {
  display: none;
}

@media (max-width: 1000px) {
  .navsalary1 {
    padding: 0 40px 0 50px;
  }
}

@media (max-width: 920px) {
  .navsalary1 .menu-btn i {
    display: block;
  }

  .navsalary1 #click:checked ~ .menu-btn i:before {
    content: "\f00d";
  }

  .navsalary1 ul {
    position: fixed;
    top: 80px;
    left: -100%;
    background: #fff;
    width: 25%;
    text-align: center;
    display: block;
    transition: all 0.3s ease;
  }

  .navsalary1 #click:checked ~ ul {
    left: 0;
  }

  .navsalary1 ul li {
    width: 100%;
  }

  .navsalary1 ul li a {
    width: 100%;
    margin-left: -100%;
    display: block;
    font-size: 20px;
    transition: 0.6s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  }

  .navsalary1 #click:checked ~ ul li a {
    margin-left: 0px;
  }

  .navsalary1 ul li a.active,
  .navsalary1 ul li a:hover {
    background: none;
    color: cyan;
  }
}

.tooltip-text {
  opacity: 0;
  position: absolute;
  background-color: #fff;
  color: #fff;
  padding: 2px;
  border-radius: 2px;
  white-space: nowrap;
  z-index: 1;
  transition: opacity 0.3s ease-in-out; 
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: .7rem;
}

.tooltip-container {
  position: relative;
  display: inline-block;
}

.tooltip-container:hover .tooltip-text {
  opacity: 1; 
}

.navsalary1 ul li {
  display: block;
}

@media (max-width: 877px) {
  .navsalary1 ul li:not(:first-child):not(:last-child) {
    display: none;
  }
}

.active-date {
  background-color: transparent !important;
}

.active-circle {
  width: 30px;
  height: 30px;
  background-color: transparent;
  color: #007bff;
  border: 2px solid #007bff;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.sticky {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
  background-color: #fff;
}

@media (max-width: 767px) {
  .tabs {
    overflow: hidden;
  }
}

.tabs {
  width: 70%;
  margin: auto;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  border-radius: 4px;
}

.tabs__items {
  display: flex;
  justify-content: flex-start;
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.tabs__items li {
  flex-grow: 1;
  flex-shrink: 0;
  border-right: 1px solid rgba(0,0,0,0.12);
  transition: 0.15s box-shadow ease-in-out;
}

.tabs__items li:last-of-type {
  border: none;
}

.tabs__items li:hover, .tabs__items li:focus {
  box-shadow: inset 0px 4px 16px -4px rgba(0,0,0,0.32);
}

.tabs__items li a {
  display: block;
  padding: 16px 24px;
  color: #232323;
  text-decoration: none;
  cursor: pointer;
}

.toggle-button {
  display: none;
  font-size: 22px;
  cursor: pointer;
  background: none;
  border: none;
  color: #000;
}

.small-screen-navbar {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #fff;
  z-index: 1000;
  padding: 2rem;
  text-align: center;
}

.small-screen-navbar ul {
  list-style: none;
  padding: 0;
  margin-top: 2rem;
}

.small-screen-navbar ul li {
  margin: 1rem 0;
}

.small-screen-navbar ul li a {
  font-size: 1rem;
  color: #000;
  text-decoration: none;
}

.small-screen-navbar {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: auto; /* Set width to auto */
  background: #fff;
  z-index: 1000;
  padding: 2rem;
  text-align: left; /* Align text to the left */
}

@media (max-width: 908px) {
  .navsalary1 {
    display: none;
  }
  .toggle-button {
    display: block;
  }
  .small-screen-navbar {
    display: block;
  }
}

.small-screen-navbar .close-button {
  position: absolute;
  top: 1rem;
  right: 1rem;
  font-size: 2rem;
  background: white !important; /* Set background to white */
  border: none !important; /* Remove border */
  padding: 0; /* Remove padding */
  cursor: pointer;
}

.calendar-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.calendar-body {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: 5px;
}

.week-days, .calendar-week {
  display: contents;
}

.week-day, .calendar-day {
  padding: 10px;
  text-align: center;
  border: 1px solid #ccc;
}
.disabled-row-payroll {
  background-color: #f0f0f0;
  pointer-events: none;
  opacity: 0.6;
}
.office-card-hover {
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.office-card-hover:hover {
  transform: scale(1.05);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}
.custom-modal .modal-content {
  background-color: #ffffff;
}

.custom-modal .modal-backdrop {
  background-color: rgba(0, 0, 0, 0.5);
}



.tagline-marquee {
  overflow: hidden;
  white-space: nowrap;
  width: 100%;
  padding: 5px 0;
  background-color: #f1f1f1; 
  color: #333; 
  height: 3rem;
  display: flex;
  align-items: center;
}

.tagline-marquee p {
  display: inline-block;
  animation: marquee 10s linear infinite;
  font-size: 16px;
  font-weight: 600;
  padding-left: 100%; /* Creates an initial gap */
}

@keyframes marquee {
  0% {
    transform: translateX(0%); /* Start from the right edge */
  }
  100% {
    transform: translateX(-100%); /* Move to the left by 100% of its own width */
  }
}


.analytics-tabs {
  display: flex;
  flex-direction: column;
}

.analytic-buttons {
  display: flex;
  justify-content: space-around;
  margin-bottom: 20px;
}

.analytic-button {
  padding:5px 10px;
  border: none;
  background-color: #fff; 
  color: #000; 
  cursor: pointer;
  border-radius: 5px;
  transition: all 0.3s;
  position: relative;
}

.analytic-button.active {
  border-top: 3px solid #007bff; 
  color: #007bff; 
}